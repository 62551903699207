import axios from 'axios';
import { generateHttpActions } from '../../../utility';

function updateSavedFiltersHttpRequest(
  moduleName,
  filterName,
  filterString,
  oldFilterName,
) {
  const httpActions = generateHttpActions('updateSavedFilters');
  const resource = 'filter';

  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      let response = null;

      response = await axios.put(resource, {
        filterName: oldFilterName,
        moduleName,
        filterString: JSON.stringify(filterString),
        newFilterName: filterName,
      });

      dispatch(httpActions.success(response));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default updateSavedFiltersHttpRequest;
