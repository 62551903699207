const ENROLLMENT_DETAILS = {
  ENROLMENT_CARD: {
    id: 'Enrollment',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
  },
  SCREENING_CARD: {
    id: 'Screening events',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
  },
  SCREEN_FAILURE_CARD: {
    id: 'Screen failure events',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
  },
  DROPOUT_CARD: {
    id: 'Dropout',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
  },
  COMPLETION_CARD: {
    id: 'Completion',
    toggleOptions: [
      { label: 'Count', key: 'currentNumber' },
      { label: 'Rate', key: 'rate' },
    ],
  },
};

const ENROLLMENT_DETAILS_CONFIGURATION_INITIAL_STATE = {};
Object.values(ENROLLMENT_DETAILS).forEach((configItem) => {
  ENROLLMENT_DETAILS_CONFIGURATION_INITIAL_STATE[configItem.id] = {
    currentToggle: configItem.toggleOptions[0].key,
    isActive: false,
  };
});

export { ENROLLMENT_DETAILS_CONFIGURATION_INITIAL_STATE, ENROLLMENT_DETAILS };
