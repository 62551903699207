import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const countryRegionTrials = (() => {
  const apiName = 'countryRegionTrials';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/trial-country-region`;
  return generatedHttpThunk.generateThunk();
})();

export default countryRegionTrials;
