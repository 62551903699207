import axios from 'axios';
import { generateHttpActions } from '../../../../utility';

function trialListFilterDataHttpRequest({
  dataTable,
  exclusive,
  region,
  filters,
  playlist = '',
}) {
  const httpActions = generateHttpActions('trialListFilters');
  const resource = 'trial-management/cascading-filter';
  const activeFilters = {
    filters,
    exclusive,
    dataTable,
    playlist,
    region,
  };
  return async (dispatch) => {
    dispatch(httpActions.start());
    try {
      const response = await axios.get(resource, { params: activeFilters });

      dispatch(httpActions.success(response.data));
    } catch (error) {
      dispatch(httpActions.error(error));
    }
  };
}

export default trialListFilterDataHttpRequest;
