import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const siteInteractionListHttpRequest = (() => {
  const apiName = 'siteInteractionList';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/site-interactions`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInteractionListHttpRequest;
