const validatePlaylist = (selectedPlaylist, dropdownlist, regex, playlist) => {
  if (!regex.test(selectedPlaylist)) {
    return { isValid: true, message: 'Special characters are not allowed.' };
  }

  if (selectedPlaylist.length > 20) {
    return {
      isValid: true,
      message: 'A maximum of 20 characters are allowed.',
    };
  }

  if (selectedPlaylist.trim().length < 3 && dropdownlist.trim().length < 3) {
    return { isValid: true, message: '' };
  }

  if (playlist.length > 0) {
    const isDuplicate = playlist.some(
      (item) =>
        item.Name.toLowerCase().trim() ===
        selectedPlaylist.toLowerCase().trim(),
    );
    if (isDuplicate) {
      return { isValid: true, message: 'list name already exists.' };
    }
  }

  if (!selectedPlaylist || !dropdownlist) {
    return { isValid: false, message: '' };
  }

  return { isValid: false, message: '' };
};

export default validatePlaylist;
