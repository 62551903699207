import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chevronDown } from 'assets/icons';
import { SafeHtmlElements } from 'components/SafeComponents';
import { keys, get } from 'lodash';

const generateSavedFilters = (filterJson, filterOptions) => {
  const filterKeys = keys(filterJson);
  const transformedLabels = [];
  filterKeys.forEach((key) => {
    const foundFilterValues = filterOptions.find(
      (columnName) => columnName.columnDisplayValue === key,
    );
    const columnFilterValues = get(foundFilterValues, 'columnValues');
    if (columnFilterValues) {
      filterJson[key].forEach((filterValue) => {
        const foundFilterLabel = columnFilterValues.find(
          (filterObject) => filterObject.value === filterValue,
        );
        const columnFilterLabel = get(foundFilterLabel, 'label');
        if (columnFilterLabel) transformedLabels.push(columnFilterLabel);
      });
    }
  });
  return transformedLabels;
};

function FilterLabels({ filterOptions, savedFilter }) {
  const [filterLabels, setFilterLabels] = useState([]);
  const containerRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [visibleChips, setVisibleChips] = useState([]);
  const [hiddenCount, setHiddenCount] = useState(0);

  useEffect(() => {
    const newFilterLabels = generateSavedFilters(
      savedFilter.filterJson,
      filterOptions,
    );
    setFilterLabels(newFilterLabels);
  }, []);

  useEffect(() => {
    if (!containerRef.current.clientWidth) return;
    const availableWidth = containerRef.current.clientWidth;
    const { totalChipWidth } = filterLabels.reduce(
      (acc, label) => {
        const chipWidth = label.length * 5 + 12 + 12 + 12;
        acc.totalChipWidth += chipWidth;
        return acc;
      },
      { totalChipWidth: 0 },
    );

    if (totalChipWidth <= availableWidth) {
      setVisibleChips(filterLabels);
      setHiddenCount([]);
      return;
    }
    const calculatedAvailableWidth = availableWidth - 50;
    const { visible, hidden } = filterLabels.reduce(
      (acc, label) => {
        const chipWidth = label.length * 8 + 8 + 20 + 5;
        if (acc.totalWidth + chipWidth > calculatedAvailableWidth) {
          acc.hidden += 1;
        } else {
          acc.visible.push(label);
          acc.totalWidth += chipWidth;
        }
        return acc;
      },
      { visible: [], hidden: 0, totalWidth: 0 },
    );
    setVisibleChips(visible);
    setHiddenCount(hidden);
  }, [filterLabels, isExpanded, containerRef.current]);

  return (
    <div
      data-testid="filter-labels"
      className="col-lg-8 col-md-8 col-6 d-flex flex-wrap filter-labels"
      ref={containerRef}
    >
      {(isExpanded ? filterLabels : visibleChips).map((filterText) => (
        <div key={`${savedFilter}-${filterText}`} className="filter-label">
          <SafeHtmlElements tagName="p">{filterText}</SafeHtmlElements>
        </div>
      ))}
      {hiddenCount > 0 && (
        <>
          <div className="divider" />
          <div className="expand-chip" data-testid="expand-chip">
            {!isExpanded && <span>+{hiddenCount}</span>}
            <img
              onClick={() => {
                setIsExpanded((prev) => !prev);
              }}
              src={chevronDown}
              className={isExpanded ? 'expanded' : ''}
              alt="icon"
              role="presentation"
            />
          </div>
        </>
      )}
    </div>
  );
}
FilterLabels.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      columnDisplayName: PropTypes.string,
      columnDisplayValue: PropTypes.string,
      columnValues: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isSelected: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  savedFilter: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    filterJson: PropTypes.any.isRequired,
  }).isRequired,
};

export default FilterLabels;
