import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const siteInteractionSummaryHttpRequest = (() => {
  const apiName = 'siteInteractionSummary';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `site-health/site/:siteId/site-summary`;
  return generatedHttpThunk.generateSiteThunk();
})();

export default siteInteractionSummaryHttpRequest;
