import {
  COUNTRY_MANAGEMENT_MODULE,
  PORTFOLIO_MANAGEMENT_MODULE,
  SITE_HEALTH_MODULE,
  TRIAL_MANAGEMENT_MODULE,
} from 'constants/savedFilters/savedFilters.constants';
import { GLOBAL_FILTER_SECTION_ID as PORTFOLIO_GLOBAL_FILTER_SECTION_ID } from 'dashboards/PortfolioManagement/constants/uiConfiguration.constants';
import { GLOBAL_FILTER_SECTION_ID as TRIAL_GLOBAL_FILTER_SECTION_ID } from 'dashboards/TrialManagement/constants/uiConfiguration.constants';
import { GLOBAL_FILTER_SECTION_ID as SITE_GLOBAL_FILTER_SECTION_ID } from 'dashboards/SiteHealth/constants/uiConfiguration.constants';
import {
  GLOBAL_FILTER_SECTION as CM_GLOBAL_FILTER_SECTION_ID,
  COUNTRY_OVERVIEW_ANALYTICS_FILTER,
} from 'dashboards/CountryManagement/constants/uiConfiguration.constant';
import {
  portfolioManagementActions,
  portfolioManagementStore,
} from 'dashboards/PortfolioManagement/store';
import { portfolioFiltersDataHttpRequest } from 'dashboards/PortfolioManagement/store/thunks';
import {
  siteHealthActions,
  siteHealthStore,
} from 'dashboards/SiteHealth/store';
import { SiteHealthFiltersHttpRequest } from 'dashboards/SiteHealth/store/thunks';
import {
  trialManagementActions,
  trialManagementStore,
} from 'dashboards/TrialManagement/store';
import {
  countryManagementStore,
  countryManagementActions,
} from 'dashboards/CountryManagement/store';
import { trialListFiltersHttpRequest } from 'dashboards/TrialManagement/store/thunks';
import { CountryManagementFiltersHttpRequest } from 'dashboards/CountryManagement/store/thunks';

const { cloneDeep, get } = require('lodash');

export const applySavedFilterByModule = (
  savedFilter,
  moduleStore,
  { type, sectionId },
  actions,
  updateFilterAction,
  moduleName,
) => {
  const currentFiltersState = cloneDeep(
    moduleStore.getState().uiState.preferencesConfigMap[sectionId][type],
  );

  let selectedMonths;
  if (moduleName === COUNTRY_MANAGEMENT_MODULE) {
    const preferencesConfigMap =
      moduleStore.getState().uiState.preferencesConfigMap[
        COUNTRY_OVERVIEW_ANALYTICS_FILTER
      ];
    selectedMonths = get(preferencesConfigMap, 'MONTHS', null);
  }

  const { filterJson } = savedFilter;
  const updatedFilters = { ...filterJson };
  if (updatedFilters.country_exclusive) {
    delete updatedFilters.country_exclusive;
  }

  currentFiltersState.activeFilters = updatedFilters;
  currentFiltersState.exclusive = {
    country_name: filterJson?.country_exclusive
      ? filterJson?.country_exclusive
      : false,
  };

  const preferencesConfig = {
    type,
    value: currentFiltersState,
    sectionId,
  };

  const { dispatch: moduleDispatch } = moduleStore;

  moduleDispatch(actions.preferencesConfigAction(preferencesConfig));

  moduleDispatch(
    updateFilterAction({
      dataTable: moduleName,
      filters: JSON.stringify(updatedFilters),
      exclusive: JSON.stringify({
        country_name: filterJson?.country_exclusive
          ? filterJson?.country_exclusive
          : false,
      }),
      months: selectedMonths,
    }),
  );
};

export const applySavedFilter = ({ savedFilter, currentDashboard }) => {
  if (currentDashboard === TRIAL_MANAGEMENT_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      trialManagementStore,
      {
        type: 'GLOBAL_FILTER_VALUE',
        sectionId: TRIAL_GLOBAL_FILTER_SECTION_ID,
      },
      trialManagementActions,
      trialListFiltersHttpRequest,
      TRIAL_MANAGEMENT_MODULE,
    );
  } else if (currentDashboard === PORTFOLIO_MANAGEMENT_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      portfolioManagementStore,
      {
        type: 'GLOBAL_FILTER_VALUE',
        sectionId: PORTFOLIO_GLOBAL_FILTER_SECTION_ID,
      },
      portfolioManagementActions,
      portfolioFiltersDataHttpRequest,
      PORTFOLIO_MANAGEMENT_MODULE,
    );
  } else if (currentDashboard === SITE_HEALTH_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      siteHealthStore,
      { type: 'GLOBAL_FILTER_VALUE', sectionId: SITE_GLOBAL_FILTER_SECTION_ID },
      siteHealthActions,
      SiteHealthFiltersHttpRequest,
      SITE_HEALTH_MODULE,
    );
  } else if (currentDashboard === COUNTRY_MANAGEMENT_MODULE) {
    applySavedFilterByModule(
      savedFilter,
      countryManagementStore,
      { type: 'GLOBAL_FILTER_VALUE', sectionId: CM_GLOBAL_FILTER_SECTION_ID },
      countryManagementActions,
      CountryManagementFiltersHttpRequest,
      COUNTRY_MANAGEMENT_MODULE,
    );
  }
};
