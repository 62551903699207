import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { mySiteHealthBookmarkHttpRequest } from 'store/thunks';
import Loader from 'components/Loader';
import { preferencesConfigAction } from 'store/actions';
import {
  MY_SITE_HEALTH_SECTION_ID,
  USER_PLAYLIST_SECTION_ID,
} from 'dashboards/SiteHealth/constants';
import validatePlaylist from 'components/BookMarkWithPlaylist/utility/validationPlaylist';
import { siteHealthStore } from 'dashboards/SiteHealth/store';
import { compassStore } from 'store';
import { check, cross, vectorIcon } from 'assets/icons';
import './MySitesHealth.section.scss';
import mySiteHealthSelector from './selector/mySiteHealthSelector';
import UpdateListNameHttpRequest from './utility/UpdateListName.utility';

function EditName({ rowData, setSidebarVisible }) {
  const { dispatch } = compassStore;
  const { dispatch: siteHealthDispatch } = siteHealthStore;

  // const { rowData.Name } = rowData;
  const regex = /^[A-Za-z0-9 ]*$/;

  const [inputValue, setInputValue] = useState(rowData.Name);
  const [showDialog, setShowDialog] = useState(false);
  const [messagebool, setMessageBool] = useState(false);

  const { edit, name, oldName, listName } = useSelector(
    mySiteHealthSelector,
    shallowEqual,
  );

  useEffect(() => {
    setInputValue(rowData.Name);
    setMessageBool(false);
  }, [rowData.Name]);

  const handleInputChange = (e) => {
    if (e.target.value.length <= 21) {
      setInputValue(e.target.value);
    }
    setMessageBool(true);
  };

  const { isValid, message } = validatePlaylist(
    inputValue,
    '',
    regex,
    listName,
  );

  const setBlockPreferences = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    dispatch(preferencesConfigAction(preferencesConfig));
  };

  const siteHealthDispatchStore = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    siteHealthDispatch(preferencesConfigAction(preferencesConfig));
  };

  const handleCross = () => {
    setBlockPreferences('EDIT', false, MY_SITE_HEALTH_SECTION_ID);
    setBlockPreferences('NAME', '', MY_SITE_HEALTH_SECTION_ID);
    setInputValue(rowData.Name);
    setMessageBool(false);
  };

  const handleSaveList = async () => {
    if (isValid) {
      return;
    }
    setShowDialog(true);
    const updateName = new UpdateListNameHttpRequest(oldName, inputValue);
    try {
      await updateName.updatelistName();
      siteHealthDispatchStore('TOGGLE_SWITCH', false, USER_PLAYLIST_SECTION_ID);
      siteHealthDispatchStore('SELECTED_LIST', '', USER_PLAYLIST_SECTION_ID);
    } catch (error) {
      // Mock
    } finally {
      setShowDialog(false);
      setBlockPreferences('EDIT', false, MY_SITE_HEALTH_SECTION_ID);
      setBlockPreferences('NAME', '', MY_SITE_HEALTH_SECTION_ID);
      setBlockPreferences('OLD_NAME', '', MY_SITE_HEALTH_SECTION_ID);
      await dispatch(mySiteHealthBookmarkHttpRequest());
    }
  };

  const handleOpenlist = (Name) => {
    siteHealthDispatchStore('TOGGLE_SWITCH', true, USER_PLAYLIST_SECTION_ID);
    siteHealthDispatchStore('SELECTED_LIST', Name, USER_PLAYLIST_SECTION_ID);
    setSidebarVisible(false);
  };

  return (
    <div>
      {edit && name === rowData.Name ? (
        <div className="main-site-list">
          <div className="validation">
            <InputText
              className="edit-list-name mt-2"
              value={inputValue}
              onChange={handleInputChange}
              id="search-input"
              data-testid="edit-list-name"
              placeholder="Enter list name"
            />
            <p
              className={
                isValid !== '' && messagebool
                  ? 'error-message-site'
                  : 'error-message-site is-visible-site'
              }
              data-testid="validation-list"
            >
              {message}
            </p>
          </div>
          <img
            src={check}
            onClick={
              inputValue && inputValue.length >= 3 ? handleSaveList : undefined
            }
            className={`${
              inputValue && inputValue.length >= 3 ? 'cursor-pointer' : ''
            }`}
            alt="check-icon"
            role="presentation"
            data-testid="check-icon"
          />
          <img
            src={vectorIcon}
            alt="vectorIcon"
            role="presentation"
            data-testid="vectorIcon-icon"
          />
          <img
            src={cross}
            onClick={handleCross}
            className="cursor-pointer"
            alt="cross-icon"
            role="presentation"
            data-testid="cross-icon"
          />
        </div>
      ) : (
        <p
          className="site-list-name cursor-pointer"
          data-testid="user-list-name"
          onKeyDown={() => {
            // Mock
          }}
          onClick={() => handleOpenlist(rowData.Name)}
        >
          {rowData.Name}
        </p>
      )}
      {showDialog && <Loader />}
    </div>
  );
}

EditName.propTypes = {
  rowData: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setSidebarVisible: PropTypes.func,
};

EditName.defaultProps = {
  setSidebarVisible: () => {},
};

export default EditName;
