import { GLOBAL_FILTER_VALUE } from 'store/reducers/multiDashboardState';
import * as UI_CONSTANTS from '../../constants';

const INITIAL_STATE = {
  preferencesConfigMap: {
    [UI_CONSTANTS.SITE_HEALTH_PERFORMANCE_SECTION_ID]: {
      IS_RECRUITMENT_TAB_ACTIVE: true,
    },
    [UI_CONSTANTS.PROTOCOL_DEVIATIONS_SECTION_ID]: {
      SELECTED_CHART: UI_CONSTANTS.DEVIATIONS_PER_PATIENT,
    },
    [UI_CONSTANTS.GLOBAL_FILTER_SECTION_ID]: {
      GLOBAL_FILTER_VALUE,
    },
    [UI_CONSTANTS.SITE_HEALTH_OVERVIEW_SECTION_ID]: {},
    [UI_CONSTANTS.SITE_INFORMATION_TRIALS_SECTION_ID]: {
      SITE_INFORMATION_TRIALS_PAGE_STATE: {
        activeFilters: {},
        search: '',
      },
      SITE_INFORMATION_TRIAL_TABLE_STATE: {
        sort: [],
        limit: 10,
        offset: 0,
      },
    },
    [UI_CONSTANTS.EDC_SITE_READINESS_SECTION_ID]: {
      ACTIVE_EDC_SUMMARY_CARD_ID: null,
    },
    [UI_CONSTANTS.SITE_INFO_KEY_PRINCIPAL_INVESTIGATORS_SECTION_ID]: {
      TABLE_STATE: {
        sort: [],
        limit: 10,
        offset: 0,
      },
    },
    [UI_CONSTANTS.USER_PLAYLIST_SECTION_ID]: {
      TOGGLE_SWITCH: false,
      SELECTED_LIST: '',
      PLAYLIST: [],
      MULTI_SELECT_SWITCH: false,
      MULTI_SELECT_LiST: [],
      SELECTED_FILTERS: {},
      BULK_BOOKMARK: false,
    },
  },
};

export default INITIAL_STATE;
