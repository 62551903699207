import { GLOBAL_FILTER_VALUE } from 'store/reducers/multiDashboardState';
import {
  COUNTRY_OVERVIEW_ANALYTICS_FILTER,
  COUNTRY_OVERVIEW_ENROLLMENT_DETAILS,
  COUNTRY_OVERVIEW_MAP_SELECTION,
  GLOBAL_FILTER_SECTION,
  SITE_SECTION,
} from '../../constants/uiConfiguration.constant';
import COUNTRY_REGION_OVERVIEWFILTER from '../../constants';
import { ENROLLMENT_DETAILS_CONFIGURATION_INITIAL_STATE } from '../../constants/countryRegionEnrollmentDetails';

const INITIAL_STATE = {
  preferencesConfigMap: {
    [COUNTRY_OVERVIEW_ANALYTICS_FILTER]: {
      REGION: COUNTRY_REGION_OVERVIEWFILTER.REGION[0].value,
      MONTHS: COUNTRY_REGION_OVERVIEWFILTER.MONTHS[0].value,
    },
    [COUNTRY_OVERVIEW_MAP_SELECTION]: {
      SELECTED_REGION: undefined,
      SELECTED_COUNTRY: undefined,
    },
    [COUNTRY_OVERVIEW_ENROLLMENT_DETAILS]: {
      ...ENROLLMENT_DETAILS_CONFIGURATION_INITIAL_STATE,
    },
    [GLOBAL_FILTER_SECTION]: {
      GLOBAL_FILTER_VALUE,
    },
    [SITE_SECTION]: {
      SEARCH: '',
    },
  },
};

export default INITIAL_STATE;
