import ThunkGenerator from './thunkGenerator.abstract';

class CountryThunkGenerator extends ThunkGenerator {
  _isGlobalFiltering = true;

  generateThunk() {
    this._logger('Generation Requested');

    const httpRequest = (requestParameters = {}, additionalParams = {}) => {
      const resource = this._resource;

      const dispatchFn = this.generateDispatcher(
        resource,
        requestParameters,
        additionalParams,
      );
      return dispatchFn;
    };

    return httpRequest;
  }
}

export default CountryThunkGenerator;
