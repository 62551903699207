import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const GlobalFilterContext = createContext({
  isFiltersCollapsed: true,
  setIsFiltersCollapsed: () => {},
  isSavedFiltersCollapsed: true,
  setIsSavedFiltersCollapsed: () => {},
  currentModule: '',
  editFilter: undefined,
  handleEditFilterAction: () => {},
  setNewFilterName: () => {},
  newFilterName: '',
  setHasLoadedFilterValues: () => {},
  hasLoadedFilterValues: false,
});

export const MODULES = [
  {
    path: 'trial-management',
    name: 'TRIAL_OVERVIEW',
  },
  {
    path: 'site-health',
    name: 'SITE_HEALTH',
  },
  {
    path: 'trial-portfolio',
    name: 'PORTFOLIO_HEALTH',
  },
  {
    path: 'country-management',
    name: 'COUNTRY_OVERVIEW',
  },
];

export const useGlobalFilterContext = () => useContext(GlobalFilterContext);

function GlobalFilterProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true);
  const [isSavedFiltersCollapsed, setIsSavedFiltersCollapsed] = useState(true);
  const [currentModule, setCurrentModule] = useState(null);
  const [newFilterName, setNewFilterName] = useState('');
  const [editFilter, setEditFilter] = useState({
    filterName: null,
    moduleName: null,
    filterValues: null,
  });

  // Function to handle editing filters
  const handleEditFilterAction = (moduleName, filterName, selectedFilter) => {
    if (moduleName !== currentModule) {
      const modulePath = MODULES.filter(
        (m) => moduleName.toLocaleLowerCase() === m.name.toLocaleLowerCase(),
      )?.[0]?.path;
      navigate(`/${modulePath}`);
    }
    setIsFiltersCollapsed(false);
    setIsSavedFiltersCollapsed(true);
    setNewFilterName(filterName);
    setEditFilter({ filterName, moduleName, filterValues: selectedFilter });
  };

  // Reset state on path change
  useEffect(() => {
    setEditFilter({ filterName: null, moduleName: null, filterValues: null });
    setIsFiltersCollapsed(true);
    setIsSavedFiltersCollapsed(true);
    const module = MODULES.filter(
      (m) =>
        m.path.toLocaleLowerCase() ===
        location.pathname.split('/')[1].toLocaleLowerCase(),
    )?.[0]?.name;
    setCurrentModule(module);
  }, [location.pathname]);

  const contextValue = useMemo(
    () => ({
      isFiltersCollapsed,
      setIsFiltersCollapsed,
      isSavedFiltersCollapsed,
      setIsSavedFiltersCollapsed,
      currentModule,
      editFilter,
      handleEditFilterAction,
      setNewFilterName,
      newFilterName,
      setEditFilter,
    }),
    [
      isFiltersCollapsed,
      newFilterName,
      isSavedFiltersCollapsed,
      currentModule,
      editFilter,
    ],
  );

  return (
    <GlobalFilterContext.Provider value={contextValue}>
      {children}
    </GlobalFilterContext.Provider>
  );
}

GlobalFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalFilterProvider;
