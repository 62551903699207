import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';

const CountryManagementFiltersHttpRequest = (() => {
  const apiName = 'countryFilters';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `trial-management/cascading-filter`;
  return generatedHttpThunk.generatePortfolioThunk();
})();

export default CountryManagementFiltersHttpRequest;
