import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const SiteKeyPrincipalInvestigator = (() => {
  const apiName = 'countryViewSiteKeyPrincipal';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/principal-investigator`;
  return generatedHttpThunk.generateThunk();
})();

export default SiteKeyPrincipalInvestigator;
