import { deleteIcon, activeEdit } from 'assets/icons';
import Error from 'components/Error';
import Loader from 'components/Loader';
import { SafeHtmlElements } from 'components/SafeComponents';
import { Toast } from 'primereact/toast';
import PropTypes from 'prop-types';
import { useGlobalFilterContext } from 'providers/GlobalFilterProvider';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import formatHttpPostErrorMessage from 'utility/errors/formatHttpPostErrorMessage';
import { useThunkSelector } from 'utility/redux';
import './SavedFiltersDisplay.scss';
import FilterLabels from './elements/FilterLabels';
import { getCurrentModule } from './utility/helper';

function SavedFiltersDisplay({
  isClearEnabled,
  viewFilter,
  clearSavedFilter,
  moduleName,
  filterOptions,
  closeSideBar,
}) {
  const [clearIntiated, setClearIntiated] = useState(false);
  const listSavedFiltersData = useThunkSelector(
    'savedFilters.listSavedFilters',
  );

  const deleteSavedFiltersData = useSelector((currentState) => {
    const { deleteSavedFilters } = currentState.http.savedFilters;
    return deleteSavedFilters;
  }, shallowEqual);
  const { handleEditFilterAction } = useGlobalFilterContext();
  const location = useLocation();
  const { currentDashboard } = getCurrentModule(location.pathname);

  let savedFiltersList = [];

  if (listSavedFiltersData.isSuccess) {
    savedFiltersList = listSavedFiltersData.payload.data.find(
      (moduleFilters) => moduleFilters.module === moduleName,
    ).filters;
  }

  const errorToast = useRef(null);
  const showErrorToast = (detail) => {
    errorToast.current.show({ severity: 'error', summary: 'Error', detail });
  };

  useEffect(() => {
    if (deleteSavedFiltersData.isError) {
      setTimeout(() => {
        showErrorToast(formatHttpPostErrorMessage(deleteSavedFiltersData));
      });
    }
  }, [deleteSavedFiltersData]);

  const enableView = moduleName === currentDashboard;

  return (
    <div className="row">
      <Toast ref={errorToast} />
      {listSavedFiltersData.isError && (
        <Error error={listSavedFiltersData.error} />
      )}
      {(listSavedFiltersData.isLoading ||
        (clearIntiated && deleteSavedFiltersData.isLoading)) && (
        <Loader className="my-5 py-5" />
      )}
      {listSavedFiltersData.isSuccess &&
        (!clearIntiated || !deleteSavedFiltersData.isLoading) && (
          <div>
            {savedFiltersList.length === 0 ? (
              <p className="no-saved-filters">No saved filters found</p>
            ) : (
              <div className="col-12 saved-filters">
                {savedFiltersList.map((savedFilter) => (
                  <div
                    className={`row w-100 align-items-start ${
                      savedFilter.validFilter ? '' : 'invalid-filter'
                    }`}
                    key={savedFilter.filterName}
                  >
                    <div className="col-lg-2 col-md-2 col-3 saved-filter-name">
                      <SafeHtmlElements tagName="h4">
                        {savedFilter.filterName}
                      </SafeHtmlElements>
                    </div>
                    <FilterLabels
                      filterOptions={filterOptions}
                      savedFilter={savedFilter}
                    />

                    <div className="col-lg-2 col-md-2 col-3 d-flex my-filter-actions">
                      {enableView && savedFilter.validFilter && (
                        <>
                          <div className="divider" />
                          <div
                            className="d-flex align-items-center cursor-pointer action-container"
                            onClick={() => {
                              viewFilter({ savedFilter, currentDashboard });
                            }}
                            data-testid={`${moduleName}-${savedFilter.filterName}`}
                          >
                            <p data-testid="apply-btn">Apply</p>
                          </div>
                        </>
                      )}
                      {enableView && savedFilter.validFilter && (
                        <>
                          <div className="divider" />

                          <div
                            className="d-flex edit-button align-items-center cursor-pointer action-container"
                            data-testid="edit-button"
                            onClick={() => {
                              handleEditFilterAction(
                                moduleName,
                                savedFilter.filterName,
                                savedFilter.filterJson,
                              );
                              closeSideBar();
                            }}
                            onKeyDown={() => {
                              // Mock
                            }}
                          >
                            <img
                              className="active-edit"
                              src={activeEdit}
                              alt="active-edit"
                              role="presentation"
                            />
                          </div>
                        </>
                      )}
                      {isClearEnabled && (
                        <>
                          <div className="divider" />
                          <div
                            className="d-flex align-items-center cursor-pointer action-container"
                            onClick={() => {
                              setClearIntiated(true);
                              clearSavedFilter({
                                filterName: savedFilter.filterName,
                                module: moduleName,
                              });
                            }}
                            data-testid="delete-button"
                          >
                            <img
                              src={deleteIcon}
                              alt="icon"
                              role="presentation"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
    </div>
  );
}

SavedFiltersDisplay.propTypes = {
  isClearEnabled: PropTypes.bool,
  viewFilter: PropTypes.func,
  clearSavedFilter: PropTypes.func,
  closeSideBar: PropTypes.func,
  moduleName: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      columnDisplayName: PropTypes.string,
      columnDisplayValue: PropTypes.string,
      columnValues: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          isSelected: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

SavedFiltersDisplay.defaultProps = {
  isClearEnabled: false,
  viewFilter: () => {},
  clearSavedFilter: () => {},
  closeSideBar: () => {},
};

export default SavedFiltersDisplay;
