import React from 'react';
import PropTypes from 'prop-types';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../universalComponents.scss';

function Loader({ loadingMessage, className }) {
  return (
    <div className={`${className}`} data-testid="loader">
      <div className="d-flex justify-content-center universal-loader-container py-3">
        <div className="d-flex justify-content-center">
          <ProgressSpinner data-testid="progress-spinner" />
          <p className="gray-message-text">{loadingMessage}</p>
        </div>
      </div>
    </div>
  );
}

Loader.propTypes = {
  loadingMessage: PropTypes.string,
  className: PropTypes.string,
};

Loader.defaultProps = {
  loadingMessage: 'Loading...',
  className: '',
};

export default Loader;
