import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import { generateHttpReducer } from 'utility';
import CountryManagementReducer from './reducers/reducer';

const countryManagementStore = configureStore({
  reducer: combineReducers({
    uiState: CountryManagementReducer,
    http: combineReducers({
      countryFilters: generateHttpReducer('countryFilters'),
      countryRegionOverview: generateHttpReducer('countryRegionOverview'),
      countrySpecificRegionData: generateHttpReducer(
        'countrySpecificRegionData',
      ),
      countryViewTrialData: generateHttpReducer('countryViewTrialData'),
      countryViewSiteKeyPrincipal: generateHttpReducer(
        'countryViewSiteKeyPrincipal',
      ),
      countryRegionTrials: generateHttpReducer('countryRegionTrials'),
      trialMetrics: generateHttpReducer('trialMetrics'),
      sitesCountryRegion: generateHttpReducer('sitesCountryRegion'),
      countryEnrollmentDetails: generateHttpReducer('countryEnrollmentDetails'),
      siteMetrics: generateHttpReducer('siteMetrics'),
      savedFilters: combineReducers({
        listSavedFilters: generateHttpReducer('listSavedFilters'),
        createSavedFilters: generateHttpReducer('createSavedFilters'),
        deleteSavedFilters: generateHttpReducer('deleteSavedFilters'),
        updateSavedFilters: generateHttpReducer('updateSavedFilters'),
      }),
    }),
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== APPLICATION_VERSION.ENV.PROD,
});

export default countryManagementStore;
