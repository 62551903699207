import CountryManagementFiltersHttpRequest from './01_CountryManagementFilters.thunk';
import CountryRegionsData from './02_CountryRegionOverview/countryRegion.thunk';
import CountrySpecificRegionData from './02_CountryRegionOverview/countrySpecificRegion.thunk';
import countryEnrollmentDetailsHttpRequest from './03_CountryView/countryEnrollmentDetailsHttpRequest';
import countryRegionTrials from './03_CountryView/countryRegionTrials';
import SiteKeyPrincipalInvestigator from './03_CountryView/siteKeyPrincipalInvestigator.thunk';
import SiteMetricsHttpRequest from './03_CountryView/siteMetricsHttpRequest';
import sitesCountryRegionHttpRequest from './03_CountryView/sitesCountryRegionHttpRequest';
import TrialData from './03_CountryView/trialDataTable.thunk';
import TrialMetricsHttpRequest from './03_CountryView/trialMetricsHttpRequest';

export { CountryManagementFiltersHttpRequest, CountryRegionsData };

export const API_LIST = [
  CountryManagementFiltersHttpRequest,
  CountryRegionsData,
  countryEnrollmentDetailsHttpRequest,
  countryRegionTrials,
  SiteKeyPrincipalInvestigator,
  SiteMetricsHttpRequest,
  sitesCountryRegionHttpRequest,
  sitesCountryRegionHttpRequest,
  TrialData,
  TrialMetricsHttpRequest,
  CountryRegionsData,
  CountrySpecificRegionData,
];
