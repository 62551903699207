import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const sitesCountryRegionHttpRequest = (() => {
  const apiName = 'sitesCountryRegion';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/sites-country-region`;
  return generatedHttpThunk.generateThunk();
})();

export default sitesCountryRegionHttpRequest;
