import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const SiteMetricsHttpRequest = (() => {
  const apiName = 'siteMetrics';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/site-metrics`;
  return generatedHttpThunk.generateThunk();
})();

export default SiteMetricsHttpRequest;
