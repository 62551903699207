import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import { generateHttpReducer } from '../../../utility';
import siteHealthReducer from './reducers/reducer';

const siteHealthStore = configureStore({
  reducer: combineReducers({
    uiState: siteHealthReducer,
    http: combineReducers({
      siteKeyFacts: generateHttpReducer('siteKeyFacts'),
      siteStartup: generateHttpReducer('siteStartup'),
      protocolDeviations: generateHttpReducer('protocolDeviations'),
      siteTrialEnrollmentAndScreening: generateHttpReducer(
        'siteTrialEnrollmentAndScreening',
      ),
      enrollmentMetrics: generateHttpReducer('enrollmentMetrics'),
      siteHealthOverview: combineReducers({
        siteHealthList: generateHttpReducer('siteHealthList'),
        siteHealthFilters: generateHttpReducer('siteHealthFilters'),
        siteCount: generateHttpReducer('siteCount'),
      }),
      siteHealthKPI: generateHttpReducer('siteHealthKPI'),
      siteInfoKeyFacts: generateHttpReducer('siteInfoKeyFacts'),
      siteInfoTrials: combineReducers({
        siteInfoTrialFilters: generateHttpReducer('siteInfoTrialFilters'),
        siteInfoTrialSummary: generateHttpReducer('siteInfoTrialSummary'),
        siteInfoTrialList: generateHttpReducer('siteInfoTrialList'),
      }),
      siteInteractions: combineReducers({
        siteInteractionSummary: generateHttpReducer('siteInteractionSummary'),
        siteInteractionList: generateHttpReducer('siteInteractionList'),
      }),
      siteInfoKeyPrincipalInvestigators: generateHttpReducer(
        'siteInfoKeyPrincipalInvestigators',
      ),
      savedFilters: combineReducers({
        listSavedFilters: generateHttpReducer('listSavedFilters'),
        createSavedFilters: generateHttpReducer('createSavedFilters'),
        deleteSavedFilters: generateHttpReducer('deleteSavedFilters'),
        updateSavedFilters: generateHttpReducer('updateSavedFilters'),
      }),
      edcSiteReadiness: combineReducers({
        edcSiteHeader: generateHttpReducer('edcSiteHeader'),
        edcDataEntry: combineReducers({
          crfsEnteredChart: generateHttpReducer('crfsEnteredChart'),
        }),
        edcDataCleaning: combineReducers({
          queryAgingChart: generateHttpReducer('queryAgingChart'),
        }),
        edcPreLock: combineReducers({
          crfsSignedChart: generateHttpReducer('crfsSignedChart'),
        }),
        edcSummaryCards: generateHttpReducer('edcSummaryCards'),
      }),
      userPlaylist: generateHttpReducer('userPlaylist'),
    }),
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== APPLICATION_VERSION.ENV.PROD,
});

export default siteHealthStore;
