import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import Loader from 'components/Loader';
import { APPLICATION_VERSION } from 'constants/appVersion.constant';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useThunkSelector } from 'utility/redux';
import showFeature from 'utility/showFeature';
import loadAppDynamics from './appdynamics';

function SecureRoute({ children }) {
  const { oktaAuth, authState } = useOktaAuth();

  const moduleAccess = useThunkSelector('moduleAccess');

  const getUserEmail = async () => {
    const user = await oktaAuth.getUser();
    const userId = user.email;
    return userId;
  };

  const setAppDynamicsUserProperty = async () => {
    const userEmail = await getUserEmail();
    loadAppDynamics(userEmail);
  };

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState.isAuthenticated && !authState.error) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      (async () => {
        await oktaAuth.signInWithRedirect();
      })();
    }

    if (
      moduleAccess.isSuccess &&
      showFeature([APPLICATION_VERSION.ENV.TEST, APPLICATION_VERSION.ENV.PROD])
    ) {
      (async () => {
        await setAppDynamicsUserProperty();
      })();
    }
  }, [oktaAuth, authState, moduleAccess]);

  if (!authState?.isAuthenticated) {
    return (
      <div className="row" data-testid="auth-loader">
        <div className="col-12 d-flex flex-column p-5 m-5">
          <Loader loadingMessage="Authenticating" />
        </div>
      </div>
    );
  }

  return children;
}

export default SecureRoute;

SecureRoute.propTypes = {
  children: PropTypes.node,
};
SecureRoute.defaultProps = {
  children: null,
};
