import GeneratedHttpThunk from 'utility/redux/generatedHttpThunk';
import { globalFilterSelector } from 'utility/redux/globalFilter.selector';

const CountryRegionsData = (() => {
  const apiName = 'countryRegionOverview';
  const generatedHttpThunk = new GeneratedHttpThunk(apiName);
  generatedHttpThunk.resource = `country-management/country-region`;
  generatedHttpThunk.syntheticResponse = {};
  generatedHttpThunk.globalFilterSelector = globalFilterSelector;
  return generatedHttpThunk.generateCountryRegionThunk();
})();

export default CountryRegionsData;
