import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const countryEnrollmentDetailsHttpRequest = (() => {
  const apiName = 'countryEnrollmentDetails';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/enrollment-details`;
  return generatedHttpThunk.generateThunk();
})();

export default countryEnrollmentDetailsHttpRequest;
