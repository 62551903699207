import {
  COUNTRY_MANAGEMENT_MODULE,
  PORTFOLIO_MANAGEMENT_MODULE,
  SITE_HEALTH_MODULE,
  TRIAL_MANAGEMENT_MODULE,
} from 'constants/savedFilters/savedFilters.constants';

export const getCurrentModule = (path) => {
  if (path.includes('/trial-management')) {
    return {
      currentDashboard: TRIAL_MANAGEMENT_MODULE,
      filterPath: '/trial-management/overview',
    };
  }
  if (path.includes('/trial-portfolio')) {
    return {
      currentDashboard: PORTFOLIO_MANAGEMENT_MODULE,
      filterPath: '/trial-portfolio',
    };
  }
  if (path.includes('/site-health')) {
    return {
      currentDashboard: SITE_HEALTH_MODULE,
      filterPath: '/site-health',
    };
  }
  if (path.includes('/country-management')) {
    return {
      currentDashboard: COUNTRY_MANAGEMENT_MODULE,
      filterPath: '/country-management/overview',
    };
  }

  return {
    currentDashboard: null,
    filterPath: null,
  };
};
export default getCurrentModule;
