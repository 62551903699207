const COUNTRY_REGION_OVERVIEWFILTER = {
  REGION: [
    {
      label: 'Region',
      value: 'Region',
    },
    {
      label: 'Country',
      value: 'Country',
    },
  ],
  MONTHS: [
    {
      label: 'Last 3 Months',
      value: 'LAST_3_MONTHS',
    },
    {
      label: 'Last 6 Months',
      value: 'LAST_6_MONTHS',
    },
    {
      label: 'Last 9 Months',
      value: 'LAST_9_MONTHS',
    },
    {
      label: 'Last 12 Months',
      value: 'LAST_12_MONTHS',
    },
  ],
  SITESTATUS: [
    { label: 'All Site Status', value: 'All Site Status' },
    {
      label: 'Active, Enrollment Complete',
      value: 'Active, Enrollment Complete',
    },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Active, Recruiting', value: 'Active, Recruiting' },
    { label: 'Withdrawn', value: 'Withdrawn' },
    { label: 'Not Selected', value: 'Not Selected' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Hold in Feasibility', value: 'Hold in Feasibility' },
    { label: 'Selected for PSV', value: 'Selected for PSV' },
    { label: 'Hold in Activation', value: 'Hold in Activation' },
    { label: 'Selected', value: 'Selected' },
    { label: 'Active, Enrolling', value: 'Active, Enrolling' },
    { label: 'Planned', value: 'Planned' },
    { label: 'Completed', value: 'Completed' },
  ],
};

export default COUNTRY_REGION_OVERVIEWFILTER;
