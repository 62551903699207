import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { shallowEqual, useSelector } from 'react-redux';
import { compassStore } from 'store';
import { useThunkSelector } from 'utility';
import { mySiteHealthBookmarkHttpRequest } from 'store/thunks';
import { generateSafeCell } from 'components/SafeComponents';
import { MY_SITE_HEALTH_SECTION_ID } from 'dashboards/SiteHealth/constants';
import { preferencesConfigAction } from 'dashboards/SiteHealth/store/actions';
import { horizontalVector } from 'assets/icons';
import CompassErrorBoundary from 'components/Error/CompassErrorBoundary';
import './MySitesHealth.section.scss';
import EditMySite from './editMySite';
import EditName from './editName';
import mySiteHealthSelector from './selector/mySiteHealthSelector';

function MySiteHealthSection({ setSidebarVisible }) {
  const { dispatch } = compassStore;

  const { listName } = useSelector(mySiteHealthSelector, shallowEqual);

  useEffect(() => {
    dispatch(mySiteHealthBookmarkHttpRequest());
  }, []);

  const setUpdateData = (type, value, sectionId) => {
    const preferencesConfig = { type, value, sectionId };
    dispatch(preferencesConfigAction(preferencesConfig));
  };

  const playlistName = useThunkSelector('mySiteHealthBookmark');

  useEffect(() => {
    if (playlistName.isSuccess && playlistName.payload) {
      setUpdateData(
        'PLAYLIST_LIST_NAME',
        playlistName.payload.data.data,
        MY_SITE_HEALTH_SECTION_ID,
      );
    }
  }, [playlistName.payload]);

  const renderEditName = (rowData) => (
    <EditName rowData={rowData} setSidebarVisible={setSidebarVisible} />
  );

  return (
    <div className="row">
      {playlistName.isSuccess && (
        <CompassErrorBoundary>
          {listName.length === 0 ? (
            <div className="header-list">
              <p>No Bookmark yet</p>
              <img
                src={horizontalVector}
                alt="check-icon"
                role="presentation"
                data-testid="horizonatl-vector-icon"
              />
              <p className="sub-mesage">
                You haven’t bookmarked any sites yet. Start adding your favorite
                sites by clicking the bookmark icon next to each site name.
              </p>
            </div>
          ) : (
            <DataTable
              key={listName.length}
              value={listName}
              rows={10}
              paginator
              scrollable
              className="list-table"
              responsiveLayout="scroll"
              data-testid="user-preferences-my-trials-table"
            >
              <Column
                field="count"
                header="Number of Sites"
                body={generateSafeCell(
                  'count',
                  '--',
                  1,
                  false,
                  null,
                  () => {},
                  'sitehealth-count',
                )}
              />
              <Column
                className="playlist-name"
                field="Name"
                header="List Name"
                body={renderEditName}
              />
              <Column body={EditMySite} />
            </DataTable>
          )}
        </CompassErrorBoundary>
      )}
    </div>
  );
}

MySiteHealthSection.propTypes = {
  setSidebarVisible: PropTypes.func,
};

MySiteHealthSection.defaultProps = {
  setSidebarVisible: () => {},
};

export default MySiteHealthSection;
