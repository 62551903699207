import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const TrialData = (() => {
  const apiName = 'countryViewTrialData';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/trials`;
  return generatedHttpThunk.generateThunk();
})();

export default TrialData;
