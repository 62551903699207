import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavigationBarItem from 'components/NavigationBarItem/NavigationBarItem';
import { useThunkSelector } from 'utility/redux';
import { MENU_ID } from './constants/routeDetails';
import useMenuSubItems from './hooks/useMenuSubItems';

function NavigationOptionsSubSection() {
  const location = useLocation();

  const isTrialManagement = location.pathname.includes('/trial-management');
  const isSiteHealth = location.pathname.includes('/site-health');
  const isCountryManagement = location.pathname.includes('/country-management');

  const portfolioHealthMenuItems = useMenuSubItems(MENU_ID.PORTFOLIO_HEALTH);

  const pageAccess = useThunkSelector('moduleAccess');

  return (
    <>
      {pageAccess.isSuccess && pageAccess.payload.trialPortfolio && (
        <NavigationBarItem
          label={<>Trial portfolio</>}
          rootPath="/trial-portfolio"
          dropdownItems={portfolioHealthMenuItems}
        />
      )}
      {pageAccess.isSuccess && pageAccess.payload.trialManagement && (
        <Link to="/trial-management/overview">
          <h4 className={isTrialManagement ? 'active' : ''}>
            Trial management
          </h4>
        </Link>
      )}
      {pageAccess.isSuccess && pageAccess.payload.countryManagement && (
        <Link to="/country-management/overview">
          <h4 className={isCountryManagement ? 'active' : ''}>
            Country management
          </h4>
        </Link>
      )}
      {pageAccess.isSuccess && pageAccess.payload.siteHealth && (
        <Link to="/site-health">
          <h4 className={isSiteHealth ? 'active' : ''}>Site management</h4>
        </Link>
      )}
    </>
  );
}

export default NavigationOptionsSubSection;
