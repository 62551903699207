/* eslint-disable */
import React from 'react';
import './_errorComponent.scss';
import TakedaLogo from './assets/takeda_logo.png';
import WarningIcon from './assets/warning.png';

function ErrorComponent() {
  return (
    <div className="error-main">
      <div className="error-container">
        <div className="logo-container">
          <img src={TakedaLogo} alt="icon" width={130} />
        </div>
        <hr />
        <div className="error-body">
          <div className="error-icon">
            <img src={WarningIcon} alt="icon" width={84} />
            <p className="error-msg">
              NO
              <br />
              ACCESS
            </p>
          </div>
          <div className="error-desc">
            <p className="error-text mb-2">
              Looks like you do not have access to COMPASS Production.
              <br />
              Please request access via this{' '}
              <a
                className="error-link"
                href="https://takeda.service-now.com/AskIT"
                target="_blank"
                rel="noopener noreferrer"
              >
                AskIT
              </a>{' '}
              link.
            </p>
            <p className="error-instruction">
              <span className="error-text">Instructions:</span>
              <br />
              Use 'Report a Problem'
              <ul className="m-0">
                <li>Description of the problem: Enter 'Need COMPASS access'</li>
                <li>Select problem area: 'Application and Software'</li>
                <li>Select software: 'COMPASS-Production'</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ErrorComponent;
