import {
  LEARNING_SITE_FROM_HELP,
  LEARNING_SITE_TRUST_CENTER,
  LEARNING_SITE_CYCLE_TIME,
  LEARNING_SITE_ASPIRE,
} from 'constants/learningSite';

export const MENU_ID = {
  PORTFOLIO_HEALTH: 'PortfolioHealthMenu',
  HELP: 'HelpMenu',
  TRIAL_MANAGEMENT: 'TrialManagementMenu',
  USER_MANAGEMENT: 'UserManagementMenu',
  COUNTRY_MANAGEMENT: 'CountryManagementMenu',
};

/**
 * @type {{[name: string]: Array<{
 *  name: string;
 *  path: string;
 *  disabled?: boolean;
 *  newTab?: boolean;
 * }>}}
 */
export const SUB_MENU_PATH = {
  [MENU_ID.PORTFOLIO_HEALTH]: [
    {
      name: 'Overview',
      path: '/trial-portfolio',
      pathname: 'Portfolio Health',
    },
    {
      name: 'Trial performance',
      path: '/trial-portfolio/trial-performance',
      pathname: 'Trial performance',
    },
    {
      name: 'Trial delivery goals',
      path: '/trial-portfolio/trial-delivery-goals',
      pathname: 'Trial Delivery Goals',
    },
    {
      name: 'Cycle times',
      path: '/trial-portfolio/cycle-time',
      pathname: 'Cycle Times',
    },
    {
      name: 'Diversity',
      path: '/trial-portfolio/portfolio-diversity',
      pathname: 'Diversity',
    },
    {
      name: 'Protocol amendments',
      path: '/trial-portfolio/protocol-amendments',
      pathname: 'Protocol Amendments',
    },
  ],
  [MENU_ID.HELP]: [
    {
      name: 'Learning site',
      path: LEARNING_SITE_FROM_HELP,
      newTab: true,
      leftIcon: true,
    },
    {
      name: 'Active issues',
      path: LEARNING_SITE_TRUST_CENTER,
      newTab: true,
      leftIcon: true,
    },
    {
      name: 'ASPIRE',
      path: LEARNING_SITE_ASPIRE,
      newTab: true,
      leftIcon: true,
    },
    {
      name: 'Cycle time',
      path: LEARNING_SITE_CYCLE_TIME,
      newTab: true,
      leftIcon: true,
    },
  ],
  [MENU_ID.TRIAL_MANAGEMENT]: [
    {
      path: '/trial-management/overview',
      pathname: 'Trial Management Overview',
    },
    {
      path: '/trial-management/details/(.*?)',
      pathname: 'Trial Management Details',
    },
  ],
  [MENU_ID.USER_MANAGEMENT]: [
    {
      path: '/user-management/admin',
      pathname: 'User Management',
      name: 'Manage users',
    },
    {
      path: '/user-management/rag',
      pathname: 'RAG Management ',
      name: 'Manage RAG',
    },
  ],
  [MENU_ID.COUNTRY_MANAGEMENT]: [
    {
      path: '/country-management/overview',
      pathname: 'Country Management Overview',
    },
  ],
};

export const BETA_ITEMS = ['Manage RAG'];
