import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const CountrySpecificRegionData = (() => {
  const apiName = 'countrySpecificRegionData';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/specific-region`;
  return generatedHttpThunk.generateThunk();
})();

export default CountrySpecificRegionData;
