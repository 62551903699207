import { CountryThunkGenerator } from 'utility/redux/thunkGenerator';

const TrialMetricsHttpRequest = (() => {
  const apiName = 'trialMetrics';
  const generatedHttpThunk = new CountryThunkGenerator(apiName);
  generatedHttpThunk.resource = `country-management/trial-metrics`;
  return generatedHttpThunk.generateThunk();
})();

export default TrialMetricsHttpRequest;
